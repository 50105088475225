import React from 'react'

export default function ContactUs() {
  return (<>
<div className='container mx-auto text-center my-10'>
    <h1 className='py-10 text-3xl'>Request A Call Back</h1>
    <p className=''>Hindustan Taxi Service is one of the leading online cab booking platform in Rajkot(Gujrat) which offers riders a stop solution to book cabs of their choice for their Intercity as well as Local travel needs. We target to grip technology as a platform to remove the gap between Riders and Cab Partners to book and accept their travel bookings in Rajkot (India). One can easily book the cab of their choice in just 30 seconds and that too with affordable fares.Request a call back to connect with us.  
    </p>
</div>
  </>
  )
}
