import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PlaceImages = () => {
  const [places, setPlaces] = useState([]);
  const [activePlace, setActivePlace] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://bhadegadiapi.taxiwalataxi.in/api/v1/getmemoryimg')
      .then(response => {
        const fetchedPlaces = response.data.data;
        setPlaces(fetchedPlaces);
        if (fetchedPlaces.length > 0) {
          setActivePlace(fetchedPlaces[0]._id);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  const handleButtonClick = (placeId) => {
    setActivePlace(placeId);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="text-lg font-semibold">Loading...</div>
      </div>
    );
  }
  return (
    <div className="container mx-auto pb-10 ">
      <h2 className="text-3xl font-bold text-center mb-8 mt-20">Explore Our Places</h2>
      <div className="flex flex-wrap justify-center gap-4 mb-8 ">
        {places.map(place => (
          <button
            key={place._id}
            onClick={() => handleButtonClick(place._id)}
            className={`px-6 py-3 font-semibold rounded-lg shadow-lg transition-transform transform hover:scale-105 focus:outline-none ${activePlace === place._id ? 'bg-black text-white' : 'bg-gray-400 text-white'} hover:bg-black`}
          > {place.placeName} </button>
        ))}
      </div>
      {activePlace && (
         <div className="flex justify-center items-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 max-w-[1200px]">
          {places.find(place => place._id === activePlace).memoryImgs.map((image, index) => (
            <div key={index} className="relative w-full h-48 overflow-hidden rounded-lg shadow-lg transition-transform transform hover:scale-105 ">
              <img
                src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${image}`}
                alt='Place Image'
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
        </div>
      )}
    </div>
  );
};

export default PlaceImages;
