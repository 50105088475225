import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function Blog() {
    const [blog, setBlog] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('https://bhadegadiapi.taxiwalataxi.in/api/v1/getblog')
            .then(response => {
                console.log('response =>', response.data.data.contacts);
                setBlog(response.data.data.contacts);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-10">
                <h1 className="text-3xl font-bold text-center mb-10">Blogs</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                    <div className="col-span-1 lg:col-span-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10">
                            {blog.map((item) => (
                                <div key={item._id} className="bg-white shadow-md rounded-lg overflow-hidden">
                                    <img src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${item.image}`} className="w-full h-60 object-cover" alt={item.title} />
                                    <div className="p-4">
                                        <div className="text-gray-500 text-sm mb-2">{new Date(item.date).toLocaleDateString()}</div>
                                        <h1 className="text-xl font-semibold mb-2">{item.name}</h1>
                                        <p className="text-gray-700">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="bg-white shadow-md rounded-lg p-4">
                        <h1 className="text-xl font-semibold mb-4">Search for Travel Content</h1>
                        <div className="flex items-center border rounded-lg overflow-hidden">
                            <input type="text" placeholder="Searching..." className="flex-1 px-4 py-2 border-r" />
                            <button className="bg-blue-500 text-white px-4 py-2">Search</button>
                        </div>
                        <div className="mt-6">
                            <h1 className="text-xl font-semibold mb-4">About Hindustan Taxi Service Car Rentals</h1>
                            <p className="text-gray-700">Hindustan Taxi Service is India’s premier chauffeur-driven car rental service. Since our inception in 2006, we have constantly strived to offer reliable, safe, and affordable cabs. Our presence in over 2000 cities across the country uniquely positions us as India’s largest geographical network of cab services. Our services include Outstation Oneway drops, Outstation Roundtrips, Hourly Local Rentals, and Airport Transfers.</p>
                        </div>
                        <div className="mt-6">
                            <h1 className="text-xl font-semibold mb-4">More Travel Topics</h1>
                            <ul className="list-disc pl-5">
                                <li>Unforgettable monsoon trip in India – A dream trip through Western Ghats</li>
                                <li>Things to do in Dandeli – A complete travel guide</li>
                                <li>Monsoon on a plate – A journey through India’s monsoon food from every state</li>
                                <li>Things to do in Chaukori – A Complete Travel Guide</li>
                                <li>Things to do at Mehrangarh Fort – A complete travel guide</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
