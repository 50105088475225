// import React, { useState, useRef } from 'react';
// import FromDetails from './AirportDetailsForm/fromDetails';
// import ToDetails from './AirportDetailsForm/toDetails';

// function AirportPage() {
//   const [airportType, setAirportType] = useState('air_from'); 

//   const [from, setFrom] = useState('');
//   const [pickupDate, setPickupDate] = useState('');
//   const [pickupTime, setPickupTime] = useState('');
//   const [person, setPerson] = useState('');

//   const fromInputRef = useRef(null);

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Add logic to handle form submission
//     console.log("Searching cars...");
//     console.log("Trip:", from);
//     console.log("Pickup Date:", pickupDate);
//     console.log("Pickup Time:", pickupTime);
//     // You might want to navigate to another page or update the state
//   };

//   const handleAirportChange = (type) => {
//     setAirportType(type);
//   };

//   return (<>
//     <div className='flex justify-center items-center w-full'>
//     <div className='text-center grid grid-cols-2 gap-0 border border-[#919191]'>

//     <button className={` flex-1 py-2 px-5 ${airportType === 'air_from' ? 'bg-customBlue text-customfontBlack text-white' : ' text-customfontBlack'} text-[13px] font-[600] border-r border-[#919191] `}  onClick={() => handleAirportChange('air_from')}> From  Airport</button>

//       <button className={` flex-1 py-2 px-5 ${airportType === 'air_to' ? 'bg-customBlue text-customfontBlack text-white' : ' text-customfontBlack'}   text-[13px] font-[600] `} onClick={() => handleAirportChange('air_to')}>  To Airport </button>
//   </div>
// </div>

// {airportType === 'air_from' && <FromDetails />}
// {airportType === 'air_to' && <ToDetails />}



// {/* from airport
// {airportType === 'air_from' && (<>
  
  
//   <form onSubmit={handleSubmit}>
//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-8 px-4 mt-12 mb-5 rounded-[16px]">
    
//         <div className="relative">
//           <input
//             type="text"
//             placeholder="Start typing city - e.g. Bangalore"
//             ref={fromInputRef}
//             value={from}
//             onChange={(e) => setFrom(e.target.value)}
//             className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
//             style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
//           />
//           <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">From Airport</label>
//         </div>
      

//           <div className="relative">
//           <input
//             type="text"
//             placeholder="Start typing city - e.g. Bangalore"
//             ref={fromInputRef}
//             value={from}
//             onChange={(e) => setFrom(e.target.value)}
//             className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
//             style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
//           />
//           <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">To Destination</label>
//         </div>


//  <div className="relative">
//           <input
//             type="number"
//             placeholder="How many person...?"
//             onChange={(e) => setPerson(e.target.value)}
//             className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
//             style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
//           />
//           <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PERSON</label>
//         </div>
      


//         <div className="relative">
//           <input
//             type="date"
//             value={pickupDate}
//             onChange={(e) => setPickupDate(e.target.value)}
//             className="border-b font-['Open_Sans'] p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
//             style={{ fontSize: '13px' }}
//           />
//           <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICK UP DATE</label>
//         </div>

     
//         <div className="relative">
//           <input
//             type="time"
//             value={pickupTime}
//             onChange={(e) => setPickupTime(e.target.value)}
//             className="border-b p-3 font-['Open_Sans'] w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
//             style={{ fontSize: '12px', '--placeholder-font-size': '15px' }}
//           />
//           <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICK UP AT TIME</label>
//         </div>

//       </div>
//       <div className="flex justify-center mt-6">
//         <button type="submit" className="bg-customBlue text-customfontBlack absolute  py-3 px-16  text-lg rounded-md font-[700] mt-[-5px] font-nunito">
//           EXPLORE CABS
//         </button>
//       </div>
//     </form>

    
//  </> )}


//  {airportType === 'air_to' && (<>
//   <form onSubmit={handleSubmit}>
//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-8 px-4 mt-12 mb-5 rounded-[16px]">

//         <div className="relative">
//           <input
//             type="text"
//             placeholder="Start typing city - e.g. Bangalore"
//             ref={fromInputRef}
//             value={from}
//             onChange={(e) => setFrom(e.target.value)}
//             className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
//             style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
//           />
//           <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">TRIP</label>
//         </div>

 
//         <div className="relative">
//           <input
//             type="text"
//             placeholder="Start typing city - e.g. Bangalore"
//             ref={fromInputRef}
//             value={from}
//             onChange={(e) => setFrom(e.target.value)}
//             className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
//             style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
//           />
//           <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICKUP ADDRESS</label>
//         </div>

 
//         <div className="relative">
//           <input
//             type="text"
//             placeholder="Start typing city - e.g. Bangalore"
//             ref={fromInputRef}
//             value={from}
//             onChange={(e) => setFrom(e.target.value)}
//             className="border-b p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200 font-['Open_Sans']"
//             style={{ fontSize: '13px', '--placeholder-font-size': '15px' }}
//           />
//           <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">DROP AIRPORT</label>
//         </div>

      
//         <div className="relative">
//           <input
//             type="date"
//             value={pickupDate}
//             onChange={(e) => setPickupDate(e.target.value)}
//             className="border-b font-['Open_Sans'] p-3 w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
//             style={{ fontSize: '13px' }}
//           />
//           <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICK UP DATE</label>
//         </div>

 
//         <div className="relative">
//           <input
//             type="time"
//             value={pickupTime}
//             onChange={(e) => setPickupTime(e.target.value)}
//             className="border-b p-3 font-['Open_Sans'] w-full bg-white text-gray-700 placeholder-gray-400 focus:outline-none transition duration-200"
//             style={{ fontSize: '12px', '--placeholder-font-size': '15px' }}
//           />
//           <label className="absolute top-[-20px] left-0 px-3 py-0 text-customfontBlack font-[700] text-[14px]">PICK UP AT TIME</label>
//         </div>

//       </div>
//       <div className="flex justify-center mt-6">
//         <button type="submit" className="bg-customBlue text-customfontBlack absolute  py-3 px-16  text-lg rounded-md font-[700] mt-[-5px] font-nunito">
//           EXPLORE CABS
//         </button>
//       </div>
//     </form>
//  </> )} */}

   
//     </> );
// }

// export default AirportPage;



import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import EightHoursForm from './HourlyRentalForms/eight_hours_form';
import TenHoursForm from './HourlyRentalForms/ten_hours_form';
import TwelveHoursForm from './HourlyRentalForms/twelve_hours_form';
import FromDetails from './AirportDetailsForm/fromDetails';
import ToDetails from './AirportDetailsForm/toDetails';


function AirportPage() {
  const [from, setFrom] = useState('');
  const [person, setPerson] = useState('');
  const [pickupDate, setPickupDate] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [loadMap, setLoadMap] = useState(false);
  const [fromPlace, setFromPlace] = useState(null);
  const fromInputRef = useRef(null);
  const navigate = useNavigate();

  const [airportType, setAirportType] = useState('air_from'); 

    const handleAirportChange = (type) => {
    setAirportType(type);
  };


  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      initPlaceAPI();
    } else {
      loadGoogleMapScript(() => {
        setLoadMap(true);
        initPlaceAPI();
      });
    }
  }, []);

  // API key of the Google Map
  const GOOGLE_MAP_API_KEY = 'AIzaSyC34N-enppR8BKspnOpDkV6in2fGsz4drI';

  // Load Google Maps JavaScript API
  const loadGoogleMapScript = (callback) => {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
    document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener('load', callback);
  };

  // Initialize the Google Places Autocomplete
  const initPlaceAPI = () => {
    const autocompleteFrom = new window.google.maps.places.Autocomplete(fromInputRef.current);
    autocompleteFrom.addListener('place_changed', () => {
      const place = autocompleteFrom.getPlace();
      setFrom(place.formatted_address);
      setFromPlace({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        name: place.name
      });
    });
  };

  console.log(fromPlace);

  const today = new Date().toISOString().split('T')[0];

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!from || !person || !pickupDate || !pickupTime) {
      alert('Please fill in all required fields.');
      return;
    }

    // Create a data object based on the current hourType
    const formData = {
      from: from,
      person: person,
      pickupDate: pickupDate,
      pickupTime: pickupTime,
    };

    // Print form data to the console
    console.log('Form Data:', formData);

    // Construct query string
    const queryString = new URLSearchParams({
      type: 'onewayTrip',
      from: encodeURIComponent(from),
      person: encodeURIComponent(person),
      pickupDate: encodeURIComponent(pickupDate),
      pickupTime: encodeURIComponent(pickupTime),
    }).toString();

    // Navigate to the new route with the query string
    navigate(`/hourlydetails?${queryString}`);
  };

  return (
    <>
     <div className='flex justify-center items-center w-full'>
   <div className='text-center grid grid-cols-2 gap-0 border border-[#919191]'>

  <button className={` flex-1 py-2 px-5 ${airportType === 'air_from' ? 'bg-customBlue text-customfontBlack text-white' : ' text-customfontBlack'} text-[13px] font-[600] border-r border-[#919191] `}  onClick={() => handleAirportChange('air_from')}> From  Airport</button>
     <button className={` flex-1 py-2 px-5 ${airportType === 'air_to' ? 'bg-customBlue text-customfontBlack text-white' : ' text-customfontBlack'}   text-[13px] font-[600] `} onClick={() => handleAirportChange('air_to')}>  To Airport </button>
   </div>
 </div>

      {airportType === 'air_from' && <FromDetails fromInputRef={fromInputRef}/>}
      {airportType === 'air_to' && <ToDetails fromInputRef={fromInputRef}/>}

     
    </>
  );
}

export default AirportPage;
