import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { LuPhone } from "react-icons/lu";
import Logo from '../../Images/logo.png';
import Download_app from '../../Images/Download app.png';
import Blog from '../../Images/blog.png';
import Contact_us from '../../Images/contact us.png';
import { Link, Outlet } from 'react-router-dom';

const Header = () => {
  const [isMobile, setIsMobile] = useState(false);


  const handleToggle = () => {
    setIsMobile(!isMobile);
  };


  const closeMobileMenu = () => {
    setIsMobile(false);
  };

  return (<>
    <header className="bg-customWhite shadow-md relative z-50 py-1">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <div className="text-2xl font-bold">
          <a href='/'> <img src={Logo} alt="Logo" className="w-24" /> </a>
        </div>
        {/* <div className={`hidden md:block ${isMobile ? 'hidden' : 'block'}`}> */}
        <div>

          <a href="tel:+90945450000" className="flex items-center text-customBlue bg-customBlue border-[1px] border-customBlue rounded-full">
          <span className={`bg-customWhite rounded-full px-2 py-[2px] hidden md:flex ${isMobile ? 'hidden' : 'flex'}` }>
    <LuPhone className="mr-1 mt-1 font-[450] flex" /> 
    <span>24x7</span>
</span>
            <a href="tel:+917600060604" className="text-customWhite px-3 font-[450]">
  +91 76000 60604
</a>
          </a>
        </div>
        <div className="hidden md:flex items-center space-x-6">
          <div className="text-center">
            <a className="flex flex-col items-center text-gray-800 hover:text-customBlue">
              <img src={Download_app} alt="Download App" className="w-9 h-9 mb-1" />
              <span className="text-[12px]">Download App</span>
            </a>
          </div>
          <div className="text-center">
            <a href='/blog' className="flex flex-col items-center text-gray-800 hover:text-customBlue">
              <img src={Blog} alt="Hindustan Blog" className="w-8 h-8 mb-1" />
              <span className="text-[12px]">Hindustan Blog</span>
            </a>
          </div>
          <div className="text-center">
            <a href='/privacy-policy' className="flex flex-col items-center text-gray-800 hover:text-customBlue">
              <img src={Contact_us} alt="Contact Us" className="w-8 h-8 mb-1" />
              <span className="text-[12px]">Privacy Policy</span>
            </a>
          </div>
        </div>
        <div className="md:hidden" onClick={handleToggle}>
          <button>
            {isMobile ? <FaTimes className="text-2xl text-gray-800" /> : <FaBars className="text-2xl text-gray-800" />}
          </button>
        </div>
      </div>
      {/* <nav className={`md:hidden ${isMobile ? 'block' : 'hidden'} bg-customWhite w-full absolute top-0 left-0`}> */}
      <nav className={`md:hidden ${isMobile ? 'block mt-[69px]' : 'hidden'} bg-customWhite w-full absolute top-0 left-0 z-40 `}>
        <div className="flex flex-col items-center py-4 space-y-4">
          <div className="flex items-center text-customBlue bg-customBlue border-[1px] border-customBlue rounded-full">
            <span className='bg-customWhite flex rounded-full px-2 py-[2px]'>
              <LuPhone className="mr-1 mt-1 font-[450]" />
              <span>24x7</span>
            </span>
            <a href="tel:+917600060604" className="text-customWhite px-3 font-[450]">
  +91 76000 60604
</a>
          </div>
          <a href="#" className="flex items-center text-gray-800 hover:text-customBlue text-[11px]" onClick={closeMobileMenu}>
            <img src={Download_app} alt="Download App" className="w-6 h-6 mr-2" /> Download App
          </a>
          <Link to='/blog'><a className="flex items-center text-gray-800 hover:text-customBlue text-[11px]" onClick={closeMobileMenu}>
            <img src={Blog} alt="Hindustan Blog" className="w-6 h-6 mr-2" /> Hindustan Blog
          </a></Link>
          <a href="/privacy-policy" className="flex items-center text-gray-800 hover:text-customBlue text-[11px]" onClick={closeMobileMenu}>
            <img src={Contact_us} alt="Contact Us" className="w-6 h-6 mr-2" /> Privacy Policy
          </a>
        </div>
      </nav>
    </header>
    <Outlet/>
    </>
  );
};

export default Header;