import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import check from '../../../Images/check.png';
import close from '../../../Images/close.png';
import Speed from '../../../Images/races.png';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import warning from '../../../Images/war2.png';


// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyB5o0F1KqyAG06ZQKhktOik-U82uEI9fuQ",
    authDomain: "sharepdf-d8389.firebaseapp.com",
    projectId: "sharepdf-d8389",
    storageBucket: "sharepdf-d8389.appspot.com",
    messagingSenderId: "608518267741",
    appId: "1:608518267741:web:1623f046b28f7d00c9feb4",
    measurementId: "G-0F30Y61YXZ"
};


const app = initializeApp(firebaseConfig);
const storage = getStorage(app);


const CarDetail = () => {
    const location = useLocation();
    const { car, actualPrice, discountPrice, formData, km, details } = location.state || {};

    const [data, setData] = useState([]);
    const [error, setError] = useState([]);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);

    const captureRef = useRef(null);
    //   const [img, setImg] = useState(car ? `https://bhadegadiapi.taxiwalataxi.in/uploads/${car.images}` : []); // Ensure car.images is defined
    const img = car ? `https://bhadegadiapi.taxiwalataxi.in/uploads/${car.Image}` : [];
    console.log('img =>', img);
    console.log('formData =>', formData);
    console.log('km =>', km);
    console.log('details =>', details);
    useEffect(() => {
        if (car) {
            const fetchData = async () => {
                try {
                    const response = await fetch('https://bhadegadiapi.taxiwalataxi.in/api/v1/getcontact');
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    const data = await response.json();
                    setData(data.data.contacts);

                } catch (error) {
                    setError(error.message);
                }
            };
            fetchData();
        }
    }, [car]);

    console.log('car =>', car);
    if (!car) {
        return <div className='text-center mt-10'>No car data available</div>;
    }
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' }); // Gets the full month name
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    }

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);

    const handleCaptureClick = async () => {
        setLoading(true);
        try {
            // Capture screenshot
            const canvas = await html2canvas(document.querySelector("#captureElement"), { useCORS: true, scale: 2 });
            const imgData = canvas.toDataURL("image/png");

            // Generate PDF with correct image size
            const pdfBlob = generatePDF(imgData);

            // Upload PDF to Firebase
            const downloadURL = await uploadPDFToFirebase(pdfBlob);

            // Share PDF via WhatsApp
            shareViaWhatsApp(downloadURL);
        } catch (error) {
            console.error("Error capturing screenshot or sharing:", error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const generatePDF = (imgData) => {
        const pdf = new jsPDF("p", "mm", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        // Adjust the image height to fit within the PDF page
        const adjustedHeight = Math.min(pdfHeight, pdf.internal.pageSize.getHeight());
        const adjustedWidth = (adjustedHeight * imgProps.width) / imgProps.height;

        // Add the image to the PDF with the adjusted size
        pdf.addImage(imgData, 'PNG', (pdfWidth - adjustedWidth) / 2, 0, adjustedWidth, adjustedHeight);

        return pdf.output("blob");
    };

    const uploadPDFToFirebase = async (pdfBlob) => {
        const storageRef = ref(storage, `pdfs/${Date.now()}.pdf`);
        await uploadBytes(storageRef, pdfBlob);
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
    };

    const shareViaWhatsApp = (downloadURL) => {
        const whatsappMessage = `Check out this PDF: ${downloadURL}`;
        const whatsappLink = `https://api.whatsapp.com/send?phone=917600060604&text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappLink, "_blank");
    };

    return (<>
        {/* <div className=" bg-gray-100  p-6 parent"  >
            <div className='container grid lg:grid-cols-8  sm:grid-cols-2 gap-5 mx-auto'>
                <div className="mx-auto bg-white shadow-lg rounded-lg p-6 lg:col-span-6 " >
                  
                    <div className="grid lg:grid-cols-6 md:grid-cols-1 sm:grid-cols-1 sm:gap-2">

                        <div className="lg:col-span-2 md:col-span-1 sm:col-span-1 " >
                            <img
                                src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${car.Image}`}
                                alt="Car"
                                className="w-full h-auto rounded-lg shadow-md"
                            />
                        </div>
                        <div className="lg:col-span-4 md:col-span-1  sm:col-span-1 " id='capture2'>
                            <h1 className="text-3xl mb-4 font-custom">{car.Name}</h1>
                            <div className="flex items-center mt-2 pb-3">
                                <div className='flex bg-[#DBD8D4] px-2 py-1 rounded'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="#000"
                                        style={{ width: '1rem', height: '1rem', marginTop: '3px' }}><path d="M9.572 2.082A1.384 1.384 0 009 3.33v5.897A2.792 2.792 0 0012 12a2.791 2.791 0 003-2.774v-5.86a1.435 1.435 0 00-.608-1.285A4.966 4.966 0 0012 1.5a5.122 5.122 0 00-2.428.582zM4.145 16.865A17.3 17.3 0 0112 15a17.608 17.608 0 017.893 1.867A2 2 0 0121 18.75v.75a3.226 3.226 0 01-3 3H6a3.226 3.226 0 01-3-3v-.75a2.073 2.073 0 011.145-1.885z"></path></svg>
                                    <span className="text-black text-[13px] font-[600] ml-1" > {car.seats}</span>
                                </div>
                                <div className='flex bg-[#DBD8D4] px-2 py-1 rounded ml-2' >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" style={{ width: '1rem', height: '1rem', marginTop: '3px' }}><path d="M12 0a1.5 1.5 0 00-1.5 1.5v2.379l-1.94-1.94a1.5 1.5 0 10-2.157 2.085l.037.037 4.06 4.06V10.5H8.121l-4.06-4.06a1.5 1.5 0 00-2.159 2.083l.037.037 1.94 1.94H1.5a1.5 1.5 0 000 3h2.379l-1.94 1.94a1.5 1.5 0 002.122 2.12l4.06-4.06H10.5v2.379l-4.06 4.06a1.5 1.5 0 002.12 2.122l1.94-1.94V22.5a1.5 1.5 0 003 0v-2.379l1.94 1.94a1.5 1.5 0 002.12-2.122l-4.06-4.06V13.5h2.379l4.06 4.06a1.5 1.5 0 002.122-2.12l-1.94-1.94H22.5a1.5 1.5 0 000-3h-2.379l1.94-1.94a1.5 1.5 0 00-2.122-2.12l-4.06 4.06H13.5V8.121l4.06-4.06a1.5 1.5 0 00-2.12-2.122L13.5 3.88V1.5A1.5 1.5 0 0012 0z"></path></svg>
                                    <span className="text-black text-[13px] font-[600] ml-1"> {car.IsAcCar}</span>
                                </div>
                                <div className='flex bg-[#DBD8D4] px-2 py-1 rounded ml-2' >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" style={{ width: '1rem', height: '1rem', marginTop: '3px' }}><g clip-path="url(#a)"><path d="M3.75 2.248V12H3a2.25 2.25 0 00-2.25 2.25v6A2.25 2.25 0 003 22.5v.375a1.125 1.125 0 002.25 0V22.5h4.5v.375a1.125 1.125 0 002.25 0V22.5a2.25 2.25 0 002.25-2.25v-6A2.25 2.25 0 0012 12h-.75V2.25A2.25 2.25 0 009 0H6a2.25 2.25 0 00-2.25 2.248zm5.625.002c.207 0 .375.168.375.375V12h-4.5V2.625c0-.207.168-.375.375-.375h3.75zM15.75 13.5a3 3 0 00-2.626-2.977c-.205-.025-.374-.19-.374-.398V2.248A2.25 2.25 0 0115 0h3a2.25 2.25 0 012.25 2.25V6H21a2.25 2.25 0 012.25 2.25v12A2.25 2.25 0 0121 22.5v.375a1.125 1.125 0 01-2.25 0V22.5h-2.625a.375.375 0 01-.375-.375V13.5zm2.625-11.25h-3.75a.375.375 0 00-.375.375V6h4.5V2.625a.375.375 0 00-.375-.375z"></path></g></svg>
                                    <span className="text-black text-[13px] font-[600] ml-1"> {car.BootSpace}</span>
                                </div>
                                <div className='flex bg-[#DBD8D4] px-2 py-1 rounded ml-2' >
                                    <img src={Speed} className='w-5 h-5' />
                                    <span className="text-black text-[13px] font-[600] ml-2"> {car.Type}</span>
                                </div>
                            </div>

                            <div className="mt-2 grid lg:grid-cols-8 md:grid-cols-6 sm:grid-cols-6 grid-cols-1  gap-4">
                                <div className="col-span-2">
                                    <h2 className="text-xl mb-2 font-regularcustom">Inclusions</h2>
                                    <div className="flex items-center mb-2">
                                        <img src={check} className="w-5 h-5" alt="Check" />
                                        <p className="ml-3">Fuel Charges</p>
                                    </div>
                                    <div className="flex items-center">
                                        <img src={check} className="w-5 h-5" alt="Check" />
                                        <p className="ml-3">Toll / State Tax</p>
                                    </div>
                                </div>

                                <div className="col-span-3">
                                    <h2 className="text-xl mb-2 font-regularcustom ">Exclusions</h2>
                                    <div className="flex items-center mb-2">
                                        <img src={close} className="w-5 h-5" alt="Close" />
                                        <p className="ml-3">Multiple Pickup / Drops</p>
                                    </div>
                                    <div className="flex items-center mb-2">
                                        <img src={close} className="w-5 h-5" alt="Close" />
                                        <p className="ml-3">Airport Entry / Parking</p>
                                    </div>

                                </div>
                                <div className='lg:mt-9 sm:mt-0 col-span-3'>
                                    <div className="flex items-center mb-2">
                                        <img src={close} className="w-5 h-5" alt="Close" />
                                        <p className="ml-3">Driver Allowance</p>
                                    </div>
                                    <div className="flex items-center">
                                        <img src={close} className="w-5 h-5" alt="Close" />
                                        <p className="ml-3">₹34/km after 280 km</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='my-5'/>
                    {data.length > 0 && (
                        <div className="">
                            <h2 className="text-2xl font-bold mb-2 text-customBlue font-custom">Contact Details</h2>
                            {data.map((item, index) => (
                                <div key={index} className="mb-4 p-4 border border-gray-300 rounded-lg shadow-sm">
                                    <p className="text-xl font-semibold text-customBlack font-regularcustom">{item.cmpName}</p>
                                    <p className="text-lg text-gray-700 font-[600]">{item.phoneno1} &nbsp; | &nbsp; {item.phoneno2}</p>
                                    <p className="text-lg text-gray-600 font-[600]">{item.address}</p>
                                    <p className="text-lg text-gray-600 font-[600]">GSTIN: 24A00PH2750Q2ZG</p>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className="py-5 ">
                        <p className="text-red-500 rounded text-lg font-bold font-regularcustom">
                            Note: This is not a confirmed booking, this is only for quotation rates. Please send an inquiry for final booking. Rates may vary.
                        </p>
                    </div>

                </div>
                <div className='lg:col-span-2 md:col-span-1'>
                    <div className='bg-white shadow-lg rounded-lg mb-4 p-6'>
                        <div className='mb-4'>
                            <h1 className='text-xl font-[500] mb-2 text-gray-800 font-custom'>Pick-up Details</h1>
                            <span className='text-md text-gray-700'>{formatDate(formData.pickupDate)}</span> &nbsp;
                            <span className='text-md text-gray-700'>{formData.formattedPickupTime}</span>
                            <p className='text-md text-gray-700'>{formData.from}</p>
                        </div>

                        <div>
                            <h1 className='text-xl font-[500] mb-2 text-gray-800 font-custom'>Drop-off Details</h1>
                            <span className='text-md text-gray-700'>{formatDate(formData.pickupDate)}</span>
                            <p className='text-md text-gray-700'>{formData.to}</p>
                        </div>
                    </div>

                    <div className='bg-white rounded-lg shadow-lg p-6'>
                        <h1 className='text-2xl font-semibold mb-4 text-gray-800 font-custom'>Price Summary</h1>
                        <div className='flex justify-between items-center mb-1'>
                            <p className='text-md text-gray-700'>Car Rental Fee</p>
                            <p className='text-green-600 text-3xl text-gray-700 font-[700]'>₹ <del>{Math.round(discountPrice)}</del></p>
                        </div>
                        <p className='text-gray-600 text-[12px]'>
                            Taxes Include {formData.type} (pick-up in {formData.to}, Customer facilities, tourism tax)
                        </p>
                        <div className='flex justify-between items-center'>
                            <p className='text-lg text-gray-700'>Discount</p>
                            <p className='text-2xl font-bold text-red-600 font-[700]'>₹ {Math.round((discountPrice - actualPrice))}</p>
                        </div>
                        <hr className='my-2' />
                        <div className='flex justify-between items-center mb-4'>
                            <p className='text-xl font-bold'>Total Amount</p>
                            <p className='text-3xl font-[700] text-blue-600'>₹ {Math.round(actualPrice)}</p>
                        </div>
                        <button className='w-full text-md bg-customBlue text-gray-800 shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] py-2 rounded-lg font-semibold hover:bg-customBlueDark transition duration-300' onClick={handleCaptureClick} >
                            Send Inquiry on WhatsApp
                        </button>
                    </div>
                </div>
            </div>
        </div> */}


        {/* {loading && (
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                            <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin"></div>
                        </div>
                    )} */}

        <div className=" bg-gray-100  p-6 parent " id="captureElement" ref={captureRef}>
            <div className='container mx-auto'>
                <div className="grid grid-cols-1 lg:grid-cols-8 gap-5">
                    <div className="mx-auto bg-white shadow-lg rounded-lg p-6 col-span-6" >
                        {/* <div className="grid grid-cols-6 gap-9"> */}
                        <div className="grid lg:grid-cols-6 md:grid-cols-1 sm:grid-cols-1 sm:gap-2">
                            <div className="lg:col-span-2 md:col-span-1 sm:col-span-1 " >
                                <img
                                    src={img}
                                    alt="Car"
                                    className="w-full h-auto rounded-lg shadow-md" />
                            </div>
                            <div className="lg:col-span-4 md:col-span-1  sm:col-span-1 ml-5 " id='capture2'>
                                <h1 className="text-xl mb-4 font-custom font-bold text-gray-800 ">{car.Name}</h1>
                                <div className="flex items-center mt-2 pb-3 gap-3">
                                    <div className='flex  px-2 py-1 bg-[#F6F7F8] shadow rounded'>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="#000"
                                            style={{ width: '13px', height: '13px', marginTop: '3px' }}><path d="M9.572 2.082A1.384 1.384 0 009 3.33v5.897A2.792 2.792 0 0012 12a2.791 2.791 0 003-2.774v-5.86a1.435 1.435 0 00-.608-1.285A4.966 4.966 0 0012 1.5a5.122 5.122 0 00-2.428.582zM4.145 16.865A17.3 17.3 0 0112 15a17.608 17.608 0 017.893 1.867A2 2 0 0121 18.75v.75a3.226 3.226 0 01-3 3H6a3.226 3.226 0 01-3-3v-.75a2.073 2.073 0 011.145-1.885z"></path></svg>
                                        <span className="text-gray-800  text-[13px] font-[600] ml-1" > {car.seats}</span>
                                    </div>
                                    <div className='flex  px-2 py-1 bg-[#F6F7F8] shadow rounded ml-2' >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" style={{ width: '13px', height: '13px', marginTop: '3px' }}><path d="M12 0a1.5 1.5 0 00-1.5 1.5v2.379l-1.94-1.94a1.5 1.5 0 10-2.157 2.085l.037.037 4.06 4.06V10.5H8.121l-4.06-4.06a1.5 1.5 0 00-2.159 2.083l.037.037 1.94 1.94H1.5a1.5 1.5 0 000 3h2.379l-1.94 1.94a1.5 1.5 0 002.122 2.12l4.06-4.06H10.5v2.379l-4.06 4.06a1.5 1.5 0 002.12 2.122l1.94-1.94V22.5a1.5 1.5 0 003 0v-2.379l1.94 1.94a1.5 1.5 0 002.12-2.122l-4.06-4.06V13.5h2.379l4.06 4.06a1.5 1.5 0 002.122-2.12l-1.94-1.94H22.5a1.5 1.5 0 000-3h-2.379l1.94-1.94a1.5 1.5 0 00-2.122-2.12l-4.06 4.06H13.5V8.121l4.06-4.06a1.5 1.5 0 00-2.12-2.122L13.5 3.88V1.5A1.5 1.5 0 0012 0z"></path></svg>
                                        <span className="text-gray-800  text-[13px] font-[600] ml-1"> {car.IsAcCar}</span>
                                    </div>
                                    <div className='flex  px-2 py-1 bg-[#F6F7F8] shadow rounded ml-2' >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" style={{ width: '13px', height: '13px', marginTop: '3px' }}><g clip-path="url(#a)"><path d="M3.75 2.248V12H3a2.25 2.25 0 00-2.25 2.25v6A2.25 2.25 0 003 22.5v.375a1.125 1.125 0 002.25 0V22.5h4.5v.375a1.125 1.125 0 002.25 0V22.5a2.25 2.25 0 002.25-2.25v-6A2.25 2.25 0 0012 12h-.75V2.25A2.25 2.25 0 009 0H6a2.25 2.25 0 00-2.25 2.248zm5.625.002c.207 0 .375.168.375.375V12h-4.5V2.625c0-.207.168-.375.375-.375h3.75zM15.75 13.5a3 3 0 00-2.626-2.977c-.205-.025-.374-.19-.374-.398V2.248A2.25 2.25 0 0115 0h3a2.25 2.25 0 012.25 2.25V6H21a2.25 2.25 0 012.25 2.25v12A2.25 2.25 0 0121 22.5v.375a1.125 1.125 0 01-2.25 0V22.5h-2.625a.375.375 0 01-.375-.375V13.5zm2.625-11.25h-3.75a.375.375 0 00-.375.375V6h4.5V2.625a.375.375 0 00-.375-.375z"></path></g></svg>
                                        <span className="text-gray-800 text-[13px] font-[600] ml-1"> {car.BootSpace}</span>
                                    </div>
                                    <div className='flex  px-2 py-1 bg-[#F6F7F8] shadow rounded ml-2' >
                                        <img src={Speed} className='w-4 h-4' />
                                        <span className="text-gray-800  text-[13px] font-[600] ml-2"> {car.Type}</span>
                                    </div>
                                </div>
                                {/* <div className="flex flex-col md:flex-row gap-6"> */}
                                <div className="grid grid-cols-4 gap-6">

                                    {/* Inclusions Section */}
                                    <div className='bg-[#F6F7F8] col-span-1 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 py-2'>
                                        <h2 className="text-[16px] font-semibold text-gray-800 text-center my-2">Inclusions</h2>
                                        <ul className="">
                                            {details[0].inclusions.map((item, index) => {
                                                const key = Object.keys(item)[0];
                                                const value = item[key];
                                                return (
                                                    <li
                                                        key={index}
                                                        className='flex items-center space-x-2 px-2 rounded-md '
                                                    >
                                                        <img src={check} className="w-4 h-4" alt="Check" />
                                                        <span className='text-[12px] font-medium text-gray-700 py-1'>{value}</span>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>

                                    {/* Exclusions Section */}
                                    <div className='bg-[#F6F7F8] col-span-3 rounded-lg shadow-md py-2'>
                                        <h2 className="text-[16px]  font-semibold text-gray-800 text-center my-2">Exclusions</h2>
                                        <div className="grid grid-cols-1 md:grid-cols-2 ">
                                            {details[1].Exclusions.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className='flex items-center space-x-2 px-2  rounded-md'>
                                                    <img src={close} className="w-4 h-4" alt="Close" />
                                                    <span className='text-[12px] font-medium text-gray-700 py-1'>{item}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='mt-5 bg-[#F6F7F8] rounded-lg shadow-md py-2'>
                            <h2 className="text-[16px]  font-semibold text-gray-800 my-2 px-2">Terms and conditions</h2>
                            <ul className='mt-2 px-2'>
                                {details[2]['Terms and conditions'].map((item, index) => (

                                    <li key={index} className='flex items-center space-x-3'>
                                        <img src={warning} className="w-5 h-5" alt="warning" />
                                        <span className='text-[14px] font-medium text-gray-700'>{item} </span>
                                    </li>
                                ))}
                            </ul>
                            {/* <div className="flex items-center mb-2">
                                        <img src={close} className="w-5 h-5" alt="Close" />
                                        <p className="ml-3">Driver Allowance</p>
                                    </div>
                                    <div className="flex items-center">
                                        <img src={close} className="w-5 h-5" alt="Close" />
                                        <p className="ml-3">₹34/km after 280 km</p>
                                    </div> */}
                        </div>

                        <hr className='my-5' />
                        {data.length > 0 && (
                            <div className="">
                                <h2 className="text-xl font-bold mb-2 text-customBlue font-custom">Contact Details</h2>
                                {data.map((item, index) => (
                                    <div key={index} className="mb-4 p-4 border border-gray-300 rounded-lg shadow-sm leading-6">
                                        <p className="text-[14px] font-semibold text-customBlack font-regularcustom ">{item.cmpName}</p>
                                        <p className="text-[14px] text-gray-700 font-[500]">{item.phoneno1} &nbsp; | &nbsp; {item.phoneno2}</p>
                                        <p className="text-[14px] text-gray-600 font-[500]">{item.address}</p>
                                        <p className="text-[14px] text-gray-600 font-[500]">GSTIN: 24A00PH2750Q2ZG</p>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="bg-red-200 p-2 rounded-xl">
                            <p className="text-red-600 rounded text-[13.5px] font-regularcustom font-[600] px-4">
                                Note:  This is not a confirmed booking, this is only for quotation rates. Please send an inquiry for final booking. Rates may vary.
                            </p>
                        </div>
                    </div>
                    <div className='col-span-2'>
                        <div className='bg-white shadow-lg rounded-lg mb-4 p-6'>
                           
                            <div className='mb-4'>
                                <h1 className='text-lg font-[600] mb-2 text-gray-800 font-custom'>Pick-up Details</h1>
                                <span className='text-sm text-gray-700'>{formatDate(formData.pickupDate)}</span> &nbsp;
                                <span className='text-sm text-gray-700'>{formData.formattedPickupTime}</span>
                                <p className='text-sm text-gray-700'>{formData.from}</p>
                               </div>
                            <div>
                                <h1 className='text-lg font-[600] mb-2 text-gray-800 font-custom'>Drop-off Details</h1>
                                <span className='text-sm text-gray-700'>{formatDate(formData.pickupDate)}</span>
                                <p className='text-sm text-gray-700'>{formData.to}</p>
                            </div>
                        </div>
                        <div className='bg-white rounded-lg shadow-lg p-6'>
                            <h1 className='text-lg font-semibold mb-4 text-gray-800 font-custom'>Price Summary</h1>
                            <div className='flex justify-between items-center mb-1'>
                                <p className='text-sm text-gray-700 font-[600]'>Car Rental Fee</p>
                                <p className='text-green-600 text-xl text-gray-700 font-[700]'>₹ <del>{Math.round(discountPrice)}</del></p>
                            </div>

                            <p className='text-gray-600 text-[12px]'>
                                Taxes Include {formData.type} (pick-up in {formData.to}, Customer facilities, tourism tax)
                            </p>
                            <div className='flex justify-between items-center'>
                                <p className='text-sm text-gray-700 font-[600]'>Discount</p>
                                <p className='text-xl font-bold text-red-600 font-[700]'>₹ {Math.round((discountPrice - actualPrice))}</p>
                            </div>
                            <hr className='my-2' />
                            <div className='flex justify-between items-center mb-4'>
                                <p className='text-lg font-bold'>Total Amount</p>
                                <p className='text-xl font-[900] text-blue-600'>₹ {Math.round(actualPrice)}</p>
                            </div>
                            <button className='w-full text-sm bg-customBlue text-gray-800 shadow-[0px_0px_0px_1px_rgba(0,0,0,0.06),0px_1px_1px_-0.5px_rgba(0,0,0,0.06),0px_3px_3px_-1.5px_rgba(0,0,0,0.06),_0px_6px_6px_-3px_rgba(0,0,0,0.06),0px_12px_12px_-6px_rgba(0,0,0,0.06),0px_24px_24px_-12px_rgba(0,0,0,0.06)] py-2 rounded-lg font-semibold hover:bg-customBlueDark transition duration-300' onClick={handleCaptureClick} >
                                Send Inquiry on WhatsApp
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

    );
}
export default CarDetail;

