import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { GoogleMap, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import check from '../../../Images/check.png';
import close from '../../../Images/close.png';
import warning from '../../../Images/war2.png';

const containerStyle = {
  height: "100%",
  width: "100%",
};

function OneWayCarDetails() {
  const [formData, setFormData] = useState({});
  const [distance, setDistance] = useState({});
  const [selectCarDetails, setSelectCarDetails] = useState([]);
  const [directionsResponse1, setDirectionsResponse1] = useState('');
  const location = useLocation();
  const API_KEY = 'AIzaSyC34N-enppR8BKspnOpDkV6in2fGsz4drI';

  const navigate = useNavigate();

  const handleViewDealClick = (car, kmsss) => {
    const actualPrice = distance?.result?.calculatedDistance * car.onewayTrip.actualRatePerKM || 0;
    const discountPrice = distance?.result?.calculatedDistance * car.onewayTrip.discountRatePerKM || 0;
    const Rate = car.onewayTrip.actualRatePerKM;
    const kms = Math.round(distance.result.distance);
    const details = [
      {
        'inclusions': [{ '0': 'Fuel Charges' }, { '1': 'Toll/Sate Tax' }]
      },
      {
        'Exclusions': [`Pay ₹${Rate}/km after ${kms}km`, 'Multiple Pickups/drops', 'Airport Entry/Parking','Driver Allowance']
      },
      {
        'Terms and conditions': [
          'Your trip has a KM limit. If your usage exceeds this limit, you will be charged for the excess KM used.',
          'The Airport Entry/Parking charge, if applicable, is not included in the fare and will be charged extra.',
          'Your trip includes one pick-up in the Pick-up city and one drop to the destination city. It does not include within-city travel.',
          'If your trip has hill climbs, the cab AC may be switched off during such climbs.'
        ]
      }
    ];
    navigate(`/car-booking/${car._id}`, { 
      state: { 
        car,
        actualPrice,
        discountPrice,
        formData,
        Rate,
        kms,
        details
      }
    });
  };

  const fetchDirections1 = useCallback(debounce((options) => {
    if (options) {
      new window.google.maps.DirectionsService().route(options, (result, status) => {
        if (status === 'OK') {
          setDirectionsResponse1(result);
        } else {
          console.error('Directions request 1 failed due to ' + status);
        }
      });
    }
  }, 1000), [directionsResponse1]);

  useEffect(() => {
    if (formData.fromPlace && formData.toPlace) {
      fetchDirections1({
        origin: { lat: formData.fromPlace.lat, lng: formData.fromPlace.lng },
        destination: { lat: formData.toPlace.lat, lng: formData.toPlace.lng },
        optimizeWaypoints: true,
        travelMode: 'DRIVING',
      });
    }
  }, [fetchDirections1, formData.fromPlace, formData.toPlace]);

  useEffect(() => {
    // Extract the query parameters from the URL
    const queryParams = new URLSearchParams(location.search);

    // Decode and parse the data
    const type = queryParams.get('type');
    const from = decodeURIComponent(queryParams.get('from'));
    const to = decodeURIComponent(queryParams.get('to'));
    const person = decodeURIComponent(queryParams.get('person'));
    const pickupDate = decodeURIComponent(queryParams.get('pickupDate'));
    const pickupTime = decodeURIComponent(queryParams.get('pickupTime'));
    const formattedPickupTime = decodeURIComponent(queryParams.get('formattedPickupTime'));
    const fromPlace = JSON.parse(decodeURIComponent(queryParams.get('fromPlace')));
    const toPlace = JSON.parse(decodeURIComponent(queryParams.get('toPlace')));

    // Set the form data in state
    setFormData({
      type,
      from,
      to,
      person,
      pickupDate,
      formattedPickupTime,
      pickupTime,
      fromPlace,
      toPlace
    });
  }, [location.search]);

  // Fetch car details when formData.type changes
  useEffect(() => {
    if (formData.type) {
      const apiUrl = `https://bhadegadiapi.taxiwalataxi.in/api/v1/searchalldata?type=${formData.type}&seats=${formData.person}`;
      const fetchCarDetails = async () => {
        try {
          const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              // Add any additional headers if required
            },
          });

          // Check if the response is successful
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          // Parse the JSON response
          const data = await response.json();
          setSelectCarDetails(data?.data?.taxiDetails || []);
        } catch (error) {
          console.error('Error:', error);
        }
      };
      fetchCarDetails();
    }
  }, [formData.type]);

  // Fetch distance when formData.from and formData.to change
  useEffect(() => {
    if (formData.from && formData.to) {
      const apiUrl = 'https://bhadegadiapi.taxiwalataxi.in/api/v1/adddistance';
      const postData = {
        cityname1: formData.from,
        cityname2: formData.to
      };

      const fetchDistance = async () => {
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Add any additional headers if required
            },
            body: JSON.stringify(postData),
          });

          // Check if the response is successful
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          // Parse the JSON response
          const data = await response.json();
          console.log('data =>',data);
          const dist = data?.result?.distance || 0;



          // console.log('dist =>',dist);
          // Calculate price based on distance
          let calculatedDistance;
          if (dist > 0 && dist < 50) {
            calculatedDistance = 125;
          } else if (dist >= 50 && dist < 100) {
            calculatedDistance = dist * 2.5;
          } else if (dist >= 100 && dist < 150) {
            calculatedDistance = dist * 2;
          } else if (dist >= 150 && dist <= 300) {
            calculatedDistance = 300;
          } else {
            calculatedDistance = dist; // Default case
          }

          setDistance({
            ...data,
            result: {
              ...data.result,
              calculatedDistance
            }
          });
        } catch (error) {
          console.error('Error:', error);
        }
      };
      fetchDistance();
    }
  }, [formData.from, formData.to]);

  const distances = distance?.result?.distance; // This could be from your distance.result.calculatedDistance
  const roundedDistance = Math.round(distances);

  console.log('selectCarDetails =>', selectCarDetails);
  return (<>

    <div className=" mx-auto px-4 py-8">
      <div className="p-6 max-w-3xl mx-auto">
        {/* <h1 className="text-4xl font-bold text-center mb-6 text-gray-800">Car Details</h1> */}
        <h2 className="text-2xl text-center mb-4 text-gray-700 font-[700]">{formData.from} - {formData.to}</h2>
        <p className="text-center text-gray-600 font-bold mb-4 text-[17px] ">{formData.pickupDate} | {formData.formattedPickupTime}</p>

        <div className="text-center">
          <p className="text-md font-semibold text-gray-800">
            Distance: <span className="text-blue-600">{roundedDistance} km</span>
          </p>
        </div>
      </div>


      <div className='grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-10 px-2 sm:px-10'>
        <div className="">
          <h2 className="text-lg font-semibold mb-4">Available Cars</h2>
          {selectCarDetails.length > 0 ? (
            selectCarDetails.map((car, index) => {
              const actualPrice = distance?.result?.calculatedDistance * car.onewayTrip.actualRatePerKM || 0;
              const discountPrice = distance?.result?.calculatedDistance * car.onewayTrip.discountRatePerKM || 0;

              return <>
                <div key={index} className="mx-auto my-8  rounded-[10px] shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
                  <div className="grid smmin:grid-cols-3 sm:grid-cols-7 smmin:gap-0 sm:gap-4">
                    <div className='p-4  smmin:col-span-1 sm:col-span-3 '>
                      <div>
                        <h2 className="text-lg font-bold pb-3">{car.Name}</h2>
                        <div className="flex items-center mt-2 pb-3">
                          <div className='flex bg-[#DBD8D4] px-2 py-1 rounded'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" fill="#000"
                              style={{ width: '1rem', height: '1rem', marginTop: '3px' }}><path d="M9.572 2.082A1.384 1.384 0 009 3.33v5.897A2.792 2.792 0 0012 12a2.791 2.791 0 003-2.774v-5.86a1.435 1.435 0 00-.608-1.285A4.966 4.966 0 0012 1.5a5.122 5.122 0 00-2.428.582zM4.145 16.865A17.3 17.3 0 0112 15a17.608 17.608 0 017.893 1.867A2 2 0 0121 18.75v.75a3.226 3.226 0 01-3 3H6a3.226 3.226 0 01-3-3v-.75a2.073 2.073 0 011.145-1.885z"></path></svg>
                            <span className="text-black text-[13px] font-[600] ml-1" > {car.seats}</span>
                          </div>
                          <div className='flex bg-[#DBD8D4] px-2 py-1 rounded ml-2' >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" style={{ width: '1rem', height: '1rem', marginTop: '3px' }}><path d="M12 0a1.5 1.5 0 00-1.5 1.5v2.379l-1.94-1.94a1.5 1.5 0 10-2.157 2.085l.037.037 4.06 4.06V10.5H8.121l-4.06-4.06a1.5 1.5 0 00-2.159 2.083l.037.037 1.94 1.94H1.5a1.5 1.5 0 000 3h2.379l-1.94 1.94a1.5 1.5 0 002.122 2.12l4.06-4.06H10.5v2.379l-4.06 4.06a1.5 1.5 0 002.12 2.122l1.94-1.94V22.5a1.5 1.5 0 003 0v-2.379l1.94 1.94a1.5 1.5 0 002.12-2.122l-4.06-4.06V13.5h2.379l4.06 4.06a1.5 1.5 0 002.122-2.12l-1.94-1.94H22.5a1.5 1.5 0 000-3h-2.379l1.94-1.94a1.5 1.5 0 00-2.122-2.12l-4.06 4.06H13.5V8.121l4.06-4.06a1.5 1.5 0 00-2.12-2.122L13.5 3.88V1.5A1.5 1.5 0 0012 0z"></path></svg>
                            <span className="text-black text-[13px] font-[600] ml-1"> {car.IsAcCar}</span>
                          </div>
                          <div className='flex bg-[#DBD8D4] px-2 py-1 rounded ml-2' >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" style={{ width: '1rem', height: '1rem', marginTop: '3px' }} ><g clip-path="url(#a)"><path d="M3.75 2.248V12H3a2.25 2.25 0 00-2.25 2.25v6A2.25 2.25 0 003 22.5v.375a1.125 1.125 0 002.25 0V22.5h4.5v.375a1.125 1.125 0 002.25 0V22.5a2.25 2.25 0 002.25-2.25v-6A2.25 2.25 0 0012 12h-.75V2.25A2.25 2.25 0 009 0H6a2.25 2.25 0 00-2.25 2.248zm5.625.002c.207 0 .375.168.375.375V12h-4.5V2.625c0-.207.168-.375.375-.375h3.75zM15.75 13.5a3 3 0 00-2.626-2.977c-.205-.025-.374-.19-.374-.398V2.248A2.25 2.25 0 0115 0h3a2.25 2.25 0 012.25 2.25V6H21a2.25 2.25 0 012.25 2.25v12A2.25 2.25 0 0121 22.5v.375a1.125 1.125 0 01-2.25 0V22.5h-2.625a.375.375 0 01-.375-.375V13.5zm2.625-11.25h-3.75a.375.375 0 00-.375.375V6h4.5V2.625a.375.375 0 00-.375-.375z"></path></g></svg>
                            <span className="text-black text-[13px] font-[600] ml-1"> {car.BootSpace}</span>
                          </div>
                        </div>

                        <div className="flex items-center">
                          <span className="text-yellow-600 font-[600] text-sm">up to {roundedDistance} km</span>
                          <span className="ml-2 text-gray-500 font-[600] text-sm">Good deal</span>
                        </div>
                      </div>

                      <div className="mt-2 text-gray-500">
                        <span className='font-[600] text-sm'>All Inclusive</span>
                      </div>
                    </div>
                    <div className="relative mr-2  smmin:col-span-1 sm:col-span-2 ">
                      <div>
                        <img src={`https://bhadegadiapi.taxiwalataxi.in/uploads/${car.Image}`} alt="Jeep Compass" className="w-full h-full mt-6 " />
                      </div>
                    </div>
                    <div className="border-l smmin:col-span-1 sm:col-span-2  border-gray-400 bg-white  justify-between rounded-r-[16px] p-4 h-full" style={{ flexFlow: 'wrap' }}>
                      <div className='text-center '>
                        <div className="text-xl font-bold text-gray-800 py-1">

                          ₹ {Math.round(actualPrice)}
                        </div>
                        <div className='py-1'>
                          <del className="text-md font-semibold text-red-500"> ₹ {Math.round(discountPrice)}</del>
                        </div>
                      </div>
                      <p className='text-center font-bold text-green-700 text-sm py-2'>Toll-Tax Inclusion</p>
                      {/* <button className="self-center mt-auto bg-customBlue mt-[-10px] font-[600] text-white py-1 px-4 text-[15px] w-full rounded">View Deal</button> */}
                      <button onClick={() => handleViewDealClick(car, actualPrice)} className="self-center mt-auto bg-customBlue mt-[10px] font-[600] text-white py-1 px-4 text-[15px] w-full rounded">View Deal</button>

                    </div>

                  </div>
                </div>
              </>
            }
            )
          ) : (
            <p>No car details available.</p>
          )}
        </div>
        <div>
          <h1 className='text-lg font-semibold '>Direction</h1>
          <div className='bg-white rounded-lg p-6 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] h-[445px] mt-7' >
            <GoogleMap googleMapsApiKey={API_KEY}
              mapContainerStyle={containerStyle}
              center={formData.fromPlace || { lat: 0, lng: 0 }}
              zoom={10}
            >
              {directionsResponse1 && (
                <DirectionsRenderer directions={directionsResponse1} />
              )}
            </GoogleMap>
          </div>
        </div>
      </div>
    </div>
    {/* <h2 className='text-3xl text-center mb-4 text-gray-700 font-[700] py-5 '>Taxi Information</h2>
    <div className='container grid lg:grid-cols-4 sm:grid-cols-1 gap-5  lg:px-5 sm:px-2 mx-auto mb-10'>
      <div className='bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg p-3 '>
        <p className='text-gary-700 font-[600] text-xl font-nunito py-2 text-center'>Inclusions</p>
        <div className='flex'>
          <img src={check} className='w-6' />
          <p className='text-md ml-2'>Fuel Chargessss</p>
        </div>
        <div className='flex mt-2'>
          <img src={check} className='w-6' />
          <p className='text-md ml-2'>Toll /State Tax</p>
        </div>
      </div>
      <div className='bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg p-3'>
        <p className='text-gary-700 font-[600] text-xl font-nunito py-2 text-center'>Exclusions </p>
        
        <div className='flex py-2'>
          <img src={close} className='w-6' />
          <p className='text-md ml-2'>multiple Pickup/drops</p>
        </div>
        <div className='flex py-2'>
          <img src={close} className='w-6' />
          <p className='text-md ml-2'>Airport Entry/Parking</p>
        </div>
        <div className='flex py-2'>
          <img src={close} className='w-6' />
          <p className='text-md ml-2'>Driver Allowance</p>
        </div>
        <div className='flex py-2 text-left'>
          <img src={close} className='w-6 h-6' />
          <p className='text-md ml-2'>₹34/km after 280 km Pay</p>
        </div>
      </div>
      <div className='bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-lg lg:col-span-2 sm:col-span-1 p-3' >
        <p className='text-gary-700 font-[600] text-lg font-nunito py-2 text-center'>T&C</p>
        <div className='flex py-2 text-left'>
          <img src={warning} className='w-8 h-8 ' />
          <p className='text-md ml-3'>Your trip has a KM limit. if you usage exceedsthis limit, you will be changed for the excess KM used.</p>
        </div>
        <div className='flex py-2 text-left'>
          <img src={warning} className='w-8 h-8 ' />
          <p className='text-md ml-3'> The Airport Entry/Parking charge, if applicable is not included in the fare and will be charged extra.</p>
        </div>
        <div className='flex py-2 text-left'>
          <img src={warning} className='w-8 h-8 ' />
          <p className='text-md ml-3'>Your trip includes one pick up in Pick-up city and one drop to destination city. It does not included within city travel.</p>
        </div>
        <div className='flex py-2 text-left'>
          <img src={warning} className='w-8 h-8 ' />
          <p className='text-md ml-3'>If Your trip has hill climbs, cab AC may be switched off during such climbs.</p>
        </div>
      </div>
    </div> */}

    <div>
    </div>

  </>
  );
}

export default OneWayCarDetails;
