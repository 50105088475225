// Footer.jsx
import React from 'react';
import { FaPhone } from 'react-icons/fa';
import { FaLocationArrow } from 'react-icons/fa';
import { FaAddressBook } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8 bottom-0">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
          <div>
            <h1 className="text-lg font-bold text-customBlue"> COMPANY </h1>
            {/* <p className="mt-2">About Us</p> */}
            <p className="mt-2"> <a href='/blogs' className='text-white'> Blogs </a> </p>
            <p className="mt-2" > <a href='/privacy-policy' className='text-white'> Privacy Policy </a></p>
            <p className="mt-2"> Download App </p>

            {/* <p className="mt-2">Terms & Conditions</p> */}
            {/* <p className="mt-2">Refunds</p> */}
          </div>
          <div>
            <h1 className="text-lg font-bold text-customBlue"> SERVICES </h1>
            <p className="mt-2"> <a href='/' className='text-white'> Local Car Rentals </a> </p>
            <p className="mt-2"> <a href='/' className='text-white'> Outstation Taxi </a> </p>
            <p className="mt-2"> <a href='/' className='text-white'> One Way Cabs </a> </p>
            <p className="mt-2"><a href='/' className='text-white'> Airport Taxi </a> </p>
          </div>
          <div>
            <h1 className="text-lg font-bold text-customBlue"> GET IN TOUCH </h1>
            <a href="tel:+917600060604" className="mt-2 text-left flex items-center text-white">
              <FaPhone size={20} className="mr-3" /> +91 76000 60604
            </a>
            <p className="mt-2 text-left flex"> <FaAddressBook size={20} className='mr-3' /> contact@hindustantaxiservice.com</p>
            <p className="mt-2 text-left flex"> <FaLocationArrow size={20} className='mr-3' /> F-22, Swastik Plaza, Yogichowk, Surat, Gujarat - 395010</p>
            <div className="flex">
              {/* You can add additional elements or icons here */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
