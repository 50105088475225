import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import playIcon from '../../Images/play.png'; // Add your play button icon here
import stopIcon from '../../Images/stop.png'; // Add your stop button icon here
import { FaInstagram } from "react-icons/fa";

export default function Testimonial() {
    const [testimonials, setTestimonials] = useState([]);
    const [playing, setPlaying] = useState(null);
    const videoRef = useRef(null); // Changed from videoRefs to a single ref

    useEffect(() => {
        // Fetch data from the API
        axios.get('https://bhadegadiapi.taxiwalataxi.in/api/v1/getTestimonial')
            .then((response) => {
                setTestimonials(response.data.data.testimonials); // Update the state with fetched data
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handlePlay = () => {
        setPlaying(true);
        videoRef.current.play();
    };

    const handleStop = () => {
        setPlaying(false);
        videoRef.current.pause();
        videoRef.current.currentTime = 0; // Reset video to start
    };
    console.log('testimonials =>', testimonials);

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="text-2xl font-bold text-center mb-8">
                Video Stories <span className="text-yellow-500">from HindustanTaxi</span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
                {testimonials.map((data) => (
                    <div key={data._id} className="flex flex-col items-center">
                        <video
                            src={`https://bhadegadiapi.taxiwalataxi.in/uploads/videos/${data.video}`} // Use the URL from API response
                            className="w-full rounded-lg shadow-lg"
                            controls
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
