import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyB5o0F1KqyAG06ZQKhktOik-U82uEI9fuQ",
  authDomain: "sharepdf-d8389.firebaseapp.com",
  projectId: "sharepdf-d8389",
  storageBucket: "sharepdf-d8389.appspot.com",
  messagingSenderId: "608518267741",
  appId: "1:608518267741:web:1623f046b28f7d00c9feb4",
  measurementId: "G-0F30Y61YXZ"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const ShareScreenshot = () => {
  const handleCaptureAndShare = async () => {
    try {
      // Capture screenshot
      const element = document.getElementById('capture');
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL('image/png');

      // Create PDF
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      const pdfOutput = pdf.output('blob');

      // Upload PDF to Firebase Storage
      const storageRef = ref(storage, 'screenshot.pdf');
      await uploadBytes(storageRef, pdfOutput);

      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      // Create WhatsApp share link
      const whatsappURL = `https://wa.me/?text=${encodeURIComponent("Here's the document you requested: " + downloadURL)}`;
      window.open(whatsappURL, '_blank');
    } catch (error) {
      console.error('Error capturing screenshot or sharing:', error);
    }
  };

  return (
    <div>
      <div id="capture" style={{ padding: '20px', backgroundColor: '#f0f0f0' }}>
        <h1>Content to Screenshot</h1>
        <p>This is the content you want to capture and share.</p>
      </div>
      <button onClick={handleCaptureAndShare}>Capture and Share to WhatsApp</button>
    </div>
  );
};

export default ShareScreenshot;
