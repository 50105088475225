import React from "react";
import BookingForm from '../pages/BookingForm';
import TripImage from "../pages/TripImage";
import OurServices from "../pages/OurServices";
import DescriptionPage from "../pages/DescriptionPage";
import Demo from '../../demo';
import Testimonial from "./testimonial";
import Memories from "./Memories";

export default function Home() {
  return (
    <>
        <BookingForm />
        <TripImage />
        <OurServices />
        <DescriptionPage />
        <Testimonial />
        <Memories />
        {/* <Demo /> */}
    </>
  );
}
