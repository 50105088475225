import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import playIcon from '../../Images/play.png'; // Add your play button icon here
import stopIcon from '../../Images/stop.png'; // Add your stop button icon here
import { FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function Testimonial() {
  const [testimonials, setTestimonials] = useState([]);
  const [playing, setPlaying] = useState(null);
  const videoRef = useRef(null); // Changed from videoRefs to a single ref

  useEffect(() => {
    // Fetch data from the API
    axios.get('https://bhadegadiapi.taxiwalataxi.in/api/v1/getTestimonial')
      .then((response) => {
        setTestimonials(response.data.data.testimonials); // Update the state with fetched data
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handlePlay = () => {
    setPlaying(true);
    videoRef.current.play();
  };

  const handleStop = () => {
    setPlaying(false);
    videoRef.current.pause();
    videoRef.current.currentTime = 0; // Reset video to start
  };
  console.log('testimonials =>', testimonials);

  return (
    <>
      <section className='mt-16'>
        <div className="movie-slide" style={{ background: '#000', color: '#fff' }} >
          <div className="text-xl ml-2 text-center py-2"> Video Stories  &nbsp;
            <span style={{ color: '#f93' }}>from HindustanTaxi</span>
          </div>

          <div className="movie-box">
            {testimonials.map((item) => {
              return (
                <div key={item.id}> {/* Make sure to include a unique key for each item */}
                  <div className="movie-info py-2 ">
                    <video
                      // ref={(el) => (videoRefs.current[index] = el)}
                      src={`https://bhadegadiapi.taxiwalataxi.in/uploads/videos/${item.video}`} // Use the URL from API response
                      poster={`https://bhadegadiapi.taxiwalataxi.in/uploads/${item.image}`} // Use the URL for the thumbnail
                      className="w-full rounded-lg mx-auto shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]"
                      controls
                      style={{ display: 'block', border: '2px solid white' }}
                      ref={videoRef}
                    />
                    <div className='p-2 flex'>
                      <div className="movie-name text-black font-bold text-[12px]" >{item.name}</div>
                      <div className="review ml-auto"><a href='https://www.instagram.com/hindustan_taxi_service/'><FaInstagram fill='black' size={22}  /> </a></div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="all-btn" style={{ marginTop: '20px' }}>
            <Link to='/testimonial'>
              <a style={{ color: 'black', textDecorationLine: 'none', fontWeight: '700' }} className='text-md'> ALL VIDEOS </a>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
